@import "variables";

h1,
h2,
h3,
h4,
h5 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.ui.form .field > label {
    color: unset;
    font-weight: 500;
}

.ui.button {
    font-family: Roboto, sans-serif;
}

.ui.modal > .header {
    font-family: unset;
    background-color: $color-teal;
    color: white;
}

.ui.modal > .content {
    font-family: unset;
}

.ui.header {
    font-family: inherit;
}

.ui.header > .icon {
    color: unset;
}

.ui.dimmer {
    background-color: rgba(0, 0, 0, 0.83);
}

.ui.modal {
    line-height: 1.5rem;
}

.ui.grid {
    margin: 0;
}

///// Date Picker
.react-datepicker__day:focus {
    outline: none !important;
}

.react-datepicker__navigation:focus {
    outline: 1px solid #8585fc;
}
