@import "variables";

html,
body {
    width: 100vw;
    height: 100vh;
}

body {
    background-color: #f9fafb;
    line-height: 1.5;
    font-family: Roboto, sans-serif;
}
