@import "variables";

.elevated {
    box-shadow: $base-box-shadow;
    padding-left: 1em;
    padding-bottom: 0.1em;
    padding-top: 0.1em;
}

.demo-header {
    font-size: 1.16rem;
    font-weight: 700;
    opacity: 0.8;
    margin-left: 1rem;
}

.tab-menu, .tab-menu-resident-doc, .tab-menu-clinician {
    background-color: #f2f2f2 !important;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.tab-menu-warning, .tab-menu-resident-doc-warning, .tab-menu-clinician-warning {
    border-radius: 0.5rem;
    border-left: 0.7rem solid red !important;
}

.tab-menu-resident-doc {
    background-color: lightblue !important;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.tab-menu-clinician {
    background-color: #EEE8AA !important;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.active-tab-menu-warning, .tab-menu-warning:hover {
    border-radius: 0.5rem;
    border-left: 0.7rem solid red !important;
}

.active-tab-menu, .tab-menu:hover {
    color: white !important;
    background-color: #00B5AD !important;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.tab-menu-note {
    font-size: 0.9rem;
}

.tab-color-resident-doc {
    color: black !important;
}

.active-tab-color-resident-doc, .tab-color-resident-doc:hover {
    color: white !important;
    background-color: #00B5AD !important;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.active-tab-color-resident-doc-warning {
    border-radius: 0.5rem;
    border-left: 0.7rem solid red !important;
}

.tab-color-clinician {
    color: black !important;
}

.active-tab-color-clinician, .tab-color-clinician:hover {
    color: white !important;
    background-color: #00B5AD !important;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.active-tab-color-clinician-warning, .tab-color-clinician-warning:hover {
    color: white !important;
    background-color: #00B5AD !important;
    border-radius: 0.5rem;
    padding: 0.5rem;
    border-left: 0.7rem solid red !important;
}

.insurance-label {
    font-size: 1.05rem;
    font-weight: bold;
}

.insurance-class {
    width: 31rem;
}

.insurance-class-1 {
    width: 17rem;
    padding-right: 1rem;
    padding-left: 0.7rem;
    padding-top: 2.1rem;
}

.c-header {
    font-size: 1.16rem;
    font-weight: 700;
    opacity: 0.8;
    margin-bottom: 7px;

    &--big {
        margin-bottom: 1.5rem;
        padding: 0.3rem;
        background: #c0c0c02e;
        text-align: center;
        border-radius: 5px;
    }

    &--small {
        font-size: 1rem;
    }
}

.c-container {
    padding: 1.6rem;
}

.button-plain {
    box-shadow: unset !important;
    border: 1px solid white;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-column-full-height {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.flex-centered {
    align-items: center;
    justify-content: center;
}

.font-medium {
    font-size: 1.2rem;
}

.center-all {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.margin-small {
    margin: 0.7rem;
}

.padding-normal {
    padding: 1rem 2rem;
}

.patient-wrapper-card {
    margin: 0.5rem;
    width: 400px;
    display: flex;
    align-items: stretch;
}

.patient-wrapper-list {
    margin: 0.5rem;
    width: 100%;
}

.patient-wrapper:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
}

.cursor-pointer {
    cursor: pointer;
}

.scroll-y {
    height: 100%;
    overflow-y: auto;
}

.no-margin {
    margin: 0 !important;
}

.page-header {
    background-color: $color-primary;
    color: white;
    position: relative;
    z-index: 10;
    min-height: 70px;
}

.page-footer {
    background-color: $color-primary;
    color: white;
    position: relative;
    z-index: 100;
    min-height: 50px;
}

.root {
    width: 100%;
    height: 100%;
}

.width-1-2 {
    width: 50% !important;
}

.pl-1 {
    padding-left: 1rem;
}

.pb-1of2 {
    padding-bottom: 0.5rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.pb-2 {
    padding-bottom: 2.5rem;
}

.pr-1 {
    padding-right: 1rem;
}

.label-error {
    color: red;
    font-size: 0.9rem;
    padding-left: 0.2rem;
}

.label-small {
    color: gray;
    font-size: 0.9rem;
    padding-bottom: 0.2rem;
    font-weight: normal;
}
.note {
    font-size: 0.9rem;
    color: $color-gray-transparent
}



.form-input {
    &-name {
        width: 50%;
    }
}

.reader-log {
    margin-left: 1rem;
}

.c-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.c-column-full {
  grid-column: 1/3;
}

.c-column-left {
  grid-column: 1/2;
}

.c-column-right {
  grid-column: 2/3;
}

.active-table-column {
    //background-image: linear-gradient(rgba(30, 144, 255, 1), rgba(30, 144, 255, 0.6)) !important;
    //background-color: rgba(0, 181, 173, 0.9) !important;
    background-color: #c2edc2 !important;
    //color: white !important;
}

.half-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}

.r-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}

.r-column-full {
    grid-column: 1/4;
}

.r-column-left {
    grid-column: 1/2;
}

.r-column-middle {
    grid-column: 2/3;
}

.r-column-right {
    grid-column: 3/4;
}

.mt-negative {
    margin-top: -10rem;
}


.c-column-left .form-input-name, .c-column-right .form-input-name {
  width: 100%;
}

table, td {
    border: 1px solid $color-primary;
    padding: 10px;
    border-collapse: collapse;
}

.table-without-border {
    padding: 10px;
    border: none
}

.td-without-border {
    padding: 10px;
    border: none
}

.table-footer {
    font-weight: bolder !important;
}

.groupSeparator {
    padding: 0.5rem;
    background: #c2edc2;
    text-align: center;
    font-weight: bold;
    height: 2.5rem;
}

.readerGroupSeparator {
    padding: 0.5rem;
    background: #66fafa;
    text-align: center;
    font-weight: bold;
    height: 2.5rem;
}

.textareaResizeNone {
    resize: none;
}

.patientStatus {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &--active {
        border-left: 8px solid  rgba(0,0181,173,0.7);
    }

    &--excluded {
        border-left: 8px solid grey;
    }

    &--data-missing {
        border-left: 8px solid rgba(255,0,0,0.7);
    }


}

.patientStatusLabel {

    &--active {
        color:green;
    }

    &--excluded {
        color: grey;
    }

    &--data-missing {
        color: red;
        font-weight: bold;
    }
}

.red-text-color {
    color: red !important;
    font-weight: bold;
}

.no-padding-inline {
    padding-inline-start: 1em !important;
}

.patient-view-font, .patient-view-font > input {
    font-weight: bold !important;
    color: black !important;
    font-size: 16px;
    pointer-events: none !important;
}

.patient-view-date-font {
    font-weight: bold !important;
    font-size: 16px;
    color: black !important;
}
